import { z, q } from "groqd";

import { LayoutVariant } from "./enums";

export const ctaButtonSelection = {
  shouldDisplay: q.boolean().optional(),
  text: q.string().optional(),
  link: q.string().optional(),
  layoutVariant: z.nativeEnum(LayoutVariant),
  callout: q
    .object({
      shouldDisplay: q.boolean(),
      text: q.string().optional(),
    })
    .optional(),
};
