import React, { AnchorHTMLAttributes } from "react";
import Link from "next/link";
import { trackEvent } from "../analytics";

import { Colorways } from "../../theme";
import { CtaButtonType } from "./types";
import { LayoutVariant } from "./enums";

type Props = CtaButtonType & {
  colorway: Colorways;
  layoutVariant: LayoutVariant;
  classNames?: string;
  onClick?: Function;
};

const STYLING_VARIANTS = {
  [LayoutVariant.Primary]: (colorway: Colorways) =>
    `border-2 border-${colorway} bg-${colorway} text-white rollover-animation ${colorway}-colorway`,
  [LayoutVariant.Secondary]: (colorway: Colorways) =>
    `border-2 border-${colorway} text-dark rollover-animation ${colorway}-colorway`,
};

type AnchorProps = {
  className: string;
  href: string;
  text: string | undefined;
  onClick: Function | undefined;
};
const Anchor = React.forwardRef(
  (props: AnchorProps, ref: React.ForwardedRef<HTMLAnchorElement>) => {
    const { className, href, text, onClick } = props;

    if (!!onClick) {
      const handleClick = (text: string | undefined, onClick: Function) => {
        trackEvent("cta_clicked", {
          cta: text,
          cta_url: href,
        });
        onClick();
      };

      return (
        <Link
          href={href}
          ref={ref}
          className={className}
          onClick={() => handleClick(text, onClick)}
        >
          {text}
        </Link>
      );
    }

    return (
      <Link
        href={href}
        className={className}
        onClick={() =>
          trackEvent("cta_clicked", {
            cta: text,
            cta_url: href,
          })
        }
      >
        <span className="relative">{text}</span>
      </Link>
    );
  },
);

Anchor.displayName = "Anchor";

export const CtaButton = ({
  classNames,
  colorway,
  layoutVariant,
  link,
  text,
  onClick,
}: Props) => {
  const variant = STYLING_VARIANTS[layoutVariant](colorway);
  const href = link ?? "#";
  const anchorClassNames = `text-link-button inline-block w-full rounded-full px-7 py-3 text-center font-semibold md:w-fit ${variant} ${classNames}`;

  return (
    <Anchor
      className={anchorClassNames}
      href={href}
      onClick={onClick}
      text={text}
    />
  );
};
