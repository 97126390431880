export type CaretDownProps = {
  className?: string;
  unsize?: boolean;
};

export function CaretDown(props: CaretDownProps) {
  return (
    <svg
      className={props.className}
      width={props.unsize ? undefined : "8"}
      height={props.unsize ? undefined : "6"}
      viewBox="0 0 8 6"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        className="fill-current"
        d="M4 3.93512L1.28577 0.884243C1.14063 0.724917 0.950535 0.636472 0.753221 0.636472C0.555906 0.636472 0.365808 0.724917 0.220675 0.884243C0.151115 0.960443 0.0955845 1.05302 0.057599 1.15613C0.0196135 1.25923 0 1.37061 0 1.48321C0 1.59582 0.0196135 1.7072 0.057599 1.8103C0.0955845 1.9134 0.151115 2.00598 0.220675 2.08218L3.49746 5.766C3.63417 5.91645 3.81342 6 3.9995 6C4.18558 6 4.36483 5.91645 4.50154 5.766L7.77933 2.08218C7.84889 2.00598 7.90442 1.9134 7.9424 1.8103C7.98039 1.7072 8 1.59582 8 1.48321C8 1.37061 7.98039 1.25923 7.9424 1.15613C7.90442 1.05302 7.84889 0.960443 7.77933 0.884243C7.63416 0.724747 7.44396 0.636197 7.24653 0.636197C7.0491 0.636197 6.85889 0.724747 6.71373 0.884243L4 3.93571V3.93512Z"
      />
    </svg>
  );
}

export default CaretDown;
