export type KebabProps = {
  className?: string;
  unsize?: boolean;
};

export function Kebab(props: KebabProps) {
  return (
    <svg
      className={props.className}
      width={props.unsize ? undefined : "5"}
      height={props.unsize ? undefined : "19"}
      viewBox="0 0 5 19"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clipPath="url(#clip0_1058_6811)">
        <path
          d="M2.5 5C3.88071 5 5 3.88071 5 2.5C5 1.11929 3.88071 0 2.5 0C1.11929 0 0 1.11929 0 2.5C0 3.88071 1.11929 5 2.5 5Z"
          fill="currentColor"
        />
        <path
          d="M2.5 11.875C3.88071 11.875 5 10.7557 5 9.375C5 7.99429 3.88071 6.875 2.5 6.875C1.11929 6.875 0 7.99429 0 9.375C0 10.7557 1.11929 11.875 2.5 11.875Z"
          fill="currentColor"
        />
        <path
          d="M2.5 18.75C3.88071 18.75 5 17.6307 5 16.25C5 14.8693 3.88071 13.75 2.5 13.75C1.11929 13.75 0 14.8693 0 16.25C0 17.6307 1.11929 18.75 2.5 18.75Z"
          fill="currentColor"
        />
      </g>
    </svg>
  );
}

export default Kebab;
