import { useEffect } from "react";
import { BodyScroll } from "../../utils/bodyScroll";
import { getClassName } from "@pairtreefamily/utils";
import Accordion, { AccordionContent, AccordionToggler } from "../accordion";
import CaretDown from "../icons/caretDown";
import CaretRight from "../icons/caretRight";
import MenuItem from "../menu/menuItem";
import NavMenuItem from "./navMenuItem";
import { HeaderNavigationProps } from "../HeaderNavigation/query";
import { Colorways } from "src/theme";
import Link from "next/link";
import { getNavItemUrl } from "../HeaderNavigation/getNavItemUrl";
import { CtaButton, LayoutVariant } from "../CtaButton";
import { LinkType } from "../HeaderNavigation/constants";

export type MobileNavProps = {
  isOpen: boolean;
};

export function MobileNavModular(
  props: MobileNavProps & HeaderNavigationProps,
) {
  useEffect(() => {
    props.isOpen ? BodyScroll.disable() : BodyScroll.enable();
  }, [props.isOpen]);

  const { navSections } = props;

  return (
    <div
      className={getClassName(
        "fixed inset-x-[0] inset-y-[-10px] z-[100]",
        "bg-white",
        "h-screen",
        "w-full",
        "flex flex-col",
        "pb-24",
      )}
    >
      <div className="w-full overflow-y-auto bg-white px-4">
        {navSections.map((section, i) => {
          const colorway = section.colorway as Colorways;
          return (
            <div key={i.toString()}>
              {section.navType === "Dropdown" && (
                <Accordion key={section._id}>
                  <MenuItem keepOpen>
                    <AccordionToggler
                      renderToggler={({ expanded }) => {
                        return (
                          <div className="flex flex-row items-center justify-between">
                            <MobileNavCategory
                              label={section.sectionLinkText ?? ""}
                              showCaret
                              isToggled={expanded}
                              colorway={colorway}
                            />
                          </div>
                        );
                      }}
                    />
                  </MenuItem>
                  {!!section.navSectionLinks &&
                    section.navSectionLinks.length > 0 && (
                      <AccordionContent>
                        <div
                          className={`flex flex-col gap-4 py-2 ${colorway}-hover`}
                        >
                          {section.headingCta.shouldDisplay && (
                            <MenuItem>
                              <Link href={section.headingCta?.link ?? "#"}>
                                <div
                                  className={`text-${colorway} text-body-17 hover:text-onyx`}
                                >
                                  {section.headingCta.text}
                                </div>
                              </Link>
                            </MenuItem>
                          )}

                          {section.navSectionLinks.map((navItem) => {
                            return (
                              <MenuItem key={navItem._key}>
                                <Link href={getNavItemUrl(navItem)}>
                                  {navItem.linkType ===
                                    LinkType.PhoneNumber && (
                                    <>
                                      {navItem.text}
                                      <div
                                        className={`text-${colorway} text-body-17`}
                                      >
                                        {getNavItemUrl(navItem)}
                                      </div>
                                    </>
                                  )}

                                  {navItem.linkType !==
                                    LinkType.PhoneNumber && (
                                    <NavMenuItem>
                                      <span className={`navLink`}>
                                        {navItem.text}
                                      </span>
                                    </NavMenuItem>
                                  )}
                                </Link>
                              </MenuItem>
                            );
                          })}
                        </div>
                      </AccordionContent>
                    )}
                </Accordion>
              )}

              {section.navType === "Single Link" && (
                <div className="flex flex-col gap-4 py-2">
                  <MenuItem>
                    <Link href={getNavItemUrl(section.singleNavItem)}>
                      <NavMenuItem>
                        <span className="text-body-1 font-bold hover:text-teal">
                          {section.sectionLinkText}
                        </span>
                      </NavMenuItem>
                    </Link>
                  </MenuItem>
                </div>
              )}
            </div>
          );
        })}
      </div>

      <div className="flex flex-row border-t-4 border-platinum pr-2  pt-6">
        <CtaButton
          colorway={Colorways.Families}
          classNames="!py-2 lg:!px-3 xl:!px-4 ml-2 text-onyx lg:text-body-13 xl:text-body-15"
          layoutVariant={LayoutVariant.Secondary}
          text="Log in"
          link="https://app.pairtreefamily.com/welcome/sign-in?_gl=1*u8oib5*_ga*NjAzNTE2MTE5LjE2OTY4Njc0NDk.*_ga_WZFVSY52HQ*MTcxMjg4NDAxMC4xMjMuMC4xNzEyODg0MDEwLjAuMC4w"
        />

        <CtaButton
          colorway={Colorways.Families}
          classNames="!py-2 lg:!px-3 xl:!px-4 ml-2 lg:text-body-13 xl:text-body-15"
          layoutVariant={LayoutVariant.Primary}
          text="Start today..."
          link="https://app.pairtreefamily.com/?_gl=1*2fpg17*_ga*NjAzNTE2MTE5LjE2OTY4Njc0NDk.*_ga_WZFVSY52HQ*MTcxMjg4NDAxMC4xMjMuMC4xNzEyODg0MDEwLjAuMC4w"
        />
      </div>
    </div>
  );
}

function MobileNavCategory(props: {
  label: string;
  showCaret?: boolean;
  isToggled?: boolean;
  colorway: Colorways;
}) {
  return (
    <div className="flex w-full flex-row items-center border-b-2 border-backgroundgray py-4 pr-3">
      <p
        className={getClassName(
          `text-body-1`,
          props.isToggled ? `text-${props.colorway}` : "text-onyx",
        )}
      >
        <b>{props.label}</b>
      </p>
      {props.showCaret &&
        (props.isToggled ? (
          <CaretDown unsize className="text ml-auto w-[1rem] text-lightgray" />
        ) : (
          <CaretRight unsize className="ml-auto w-[0.75rem]  text-lightgray" />
        ))}
    </div>
  );
}

export default MobileNavModular;
