import { ReactNode } from "react";

function NavMenuItem(props: { children: ReactNode }) {
  return (
    <span className="text-body-2-medium flex flex-row items-center hover:text-green">
      {props.children}
    </span>
  );
}

export default NavMenuItem;
