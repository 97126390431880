import { useState } from "react";
import { useRouter } from "next/router";

import Close from "../icons/close";
import Kebab from "../icons/kebab";
import Menu from "../menu";
import Logo from "../navBar/logo";
import { HeaderNavigationProps } from "./query";
import { getClassName } from "@pairtreefamily/utils";
import NavBarItem from "../navBar/navBarItem";
import Link from "next/link";
import { CtaButton } from "../CtaButton";
import { Colorways } from "src/theme";
import { LayoutVariant } from "../CtaButton";
import MobileNavModular from "../navBar/mobileNavModular";
import { DropDownMenuItem } from "./DropDownMenuItem";
import { NavSectionType } from "./constants";
import { getNavItemUrl } from "./getNavItemUrl";

export type NavSections = HeaderNavigationProps["navSections"];
export type NavSection = NavSections[number];

export default function HeaderNavigation(props: HeaderNavigationProps) {
  const { navSections } = props;
  const router = useRouter();

  const [mobileMenuOpened, setMobileMenuOpened] = useState<boolean>(false);

  return (
    <div className="t-0 fixed z-50 h-20 w-full bg-white px-4 lg:drop-shadow-lg">
      <div className="flex h-full items-center justify-between lg:justify-normal">
        <Link href="/">
          <Logo />
        </Link>
        <div className="flex h-full w-full justify-between">
          <ul className="flex gap-4 lg:ml-20 lg:h-full lg:gap-7">
            {navSections.map((section, i) => {
              const path = router.pathname.includes("slug")
                ? router.asPath
                : router.pathname;
              const shouldHighlight =
                !!section.highlightablePaths?.includes(path);

              return (
                <li
                  key={section._id}
                  className={getClassName("ml-auto", "hidden h-full lg:block")}
                >
                  {section.navType === NavSectionType.SingleLink && (
                    <NavBarItem isActive={shouldHighlight}>
                      <Link href={getNavItemUrl(section.singleNavItem)}>
                        <span className="hover:text-teal">
                          {section.sectionLinkText}
                        </span>
                      </Link>
                    </NavBarItem>
                  )}

                  {section.navType === NavSectionType.Dropdown && (
                    <DropDownMenuItem {...section} isActive={shouldHighlight} />
                  )}
                </li>
              );
            })}
          </ul>

          <div className="flex lg:hidden">
            <Menu
              hideArrow={false}
              className="!w-[100vw] !max-w-full"
              id="mobile-nav-trigger"
              elementTrigger={(isOpen) => {
                setMobileMenuOpened(isOpen);
                return (
                  <>
                    {!isOpen && (
                      <Kebab className="m-4 mr-2 mt-6 w-[1rem] cursor-pointer" />
                    )}
                    {isOpen && (
                      <Close
                        unsize
                        className="m-4 w-[1rem] cursor-pointer text-teal"
                      />
                    )}
                  </>
                );
              }}
            >
              <MobileNavModular
                isOpen={mobileMenuOpened}
                navSections={navSections}
              />
            </Menu>
          </div>

          <div className="hidden pt-4 xl:block">
            <CtaButton
              colorway={Colorways.Families}
              classNames="!py-2 lg:!px-3 xl:!px-4 ml-2 text-onyx lg:text-link-button border-none"
              layoutVariant={LayoutVariant.Secondary}
              text="Log in"
              link="https://app.pairtreefamily.com/welcome/sign-in?_gl=1*u8oib5*_ga*NjAzNTE2MTE5LjE2OTY4Njc0NDk.*_ga_WZFVSY52HQ*MTcxMjg4NDAxMC4xMjMuMC4xNzEyODg0MDEwLjAuMC4w"
            />

            <CtaButton
              colorway={Colorways.Families}
              classNames="!py-2 lg:!px-3 xl:!px-4 ml-2 lg:text-link-button"
              layoutVariant={LayoutVariant.Primary}
              text="Start today..."
              link="https://app.pairtreefamily.com/?_gl=1*2fpg17*_ga*NjAzNTE2MTE5LjE2OTY4Njc0NDk.*_ga_WZFVSY52HQ*MTcxMjg4NDAxMC4xMjMuMC4xNzEyODg0MDEwLjAuMC4w"
            />
          </div>
        </div>
      </div>
    </div>
  );
}
