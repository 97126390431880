import { Colorways } from "../../theme";
import { Arrow } from "./Arrow";
import { CtaButtonType } from "./types";

type Props = {
  arrowClassNames?: string;
  callout: CtaButtonType["callout"];
  colorway: Colorways;
  textClassNames?: string;
  wrapperClassNames?: string;
};

export const CtaButtonCallout = ({
  arrowClassNames,
  callout,
  colorway,
  textClassNames,
  wrapperClassNames,
}: Props) => {
  return (
    <div
      className={`order-2 col-span-2 my-3 ml-[60px] grid grid-cols-[auto_1fr] items-center md:order-3 md:mb-0 ${wrapperClassNames}`}
    >
      <Arrow
        className={`m-o h-auto w-full max-w-36 text-${colorway} ${arrowClassNames}`}
      />

      <p
        className={`text-handwriting-2 text-${colorway} ml-3 max-w-[240px] ${textClassNames}`}
      >
        {callout?.text}
      </p>
    </div>
  );
};
