import { LinkType } from "./constants";

export function getNavItemUrl(navItem: any) {
  if (navItem.linkType === LinkType.CustomPage) {
    return navItem.otherPageLink;
  } else if (navItem.linkType === LinkType.ModularPage) {
    return navItem.modularPageLink.slug;
  } else if (navItem.linkType === LinkType.PhoneNumber) {
    return `${navItem.phoneLink}`;
  } else {
    return "#";
  }
}
