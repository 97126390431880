import React, {
  ReactElement,
  ReactNode,
  createContext,
  useCallback,
  useContext,
} from "react";
import { useState } from "react";
import { getClassName } from "@pairtreefamily/utils";
import Tippy from "@tippyjs/react";
import { Instance, Props } from "tippy.js";
import "tippy.js/dist/tippy.css"; // optional
import { Colors } from "../../types/colors";

const colorMap = {
  white: "[&>.tippy-arrow]:text-white",
  darkblue: "[&>.tippy-arrow]:text-darkblue",
  backgroundgray: "[&>.tippy-arrow]:text-backgroundgray",
  teal: "[&>.tippy-arrow]:text-teal",
  green: "[&>.tippy-arrow]:text-green",
};

const colorBgMap = {
  white: "[&>.tippy-content]:bg-white",
  darkblue: "[&>.tippy-content]:bg-darkblue",
  backgroundgray: "[&>.tippy-content]:bg-backgroundgray",
  teal: "[&>.tippy-content]:bg-teal",
  green: "[&>.tippy-content]:bg-green",
};

export type MenuState = {
  isOpen: boolean;
  toggle: () => void;
  id?: string;
};
const MenuContext = createContext<MenuState | null>(null);

export function useMenuContext(): MenuState {
  const context = useContext<MenuState | null>(MenuContext);
  if (!context) {
    throw new Error(
      "Tried to access menu context outside a suitable context provider.",
    );
  }
  return context;
}

export type MenuProps = {
  id: string;
  className?: string;
  elementTrigger: (isOpen: boolean) => ReactNode;
  openOnHover?: boolean;
  children?: ReactElement;
  onShowTooltip?: () => void;
  onHideTooltip?: () => void;
  tooltipBgColor?: Extract<
    Colors.Solid,
    "white" | "backgroundgray" | "teal" | "darkblue"
  >;
  hideArrow?: boolean;
};

export function Menu(props: MenuProps) {
  const [tippy, setTippy] = useState<Instance<Props>>();

  const [isOpen, setIsOpen] = useState<boolean>(false);
  const toggle = useCallback(() => {
    isOpen ? tippy?.hide() : tippy?.show();
    setIsOpen(!isOpen);
  }, [isOpen, tippy]);

  return (
    <MenuContext.Provider value={{ isOpen, toggle, id: props.id }}>
      <Tippy
        onShow={props.onShowTooltip}
        onHide={props.onHideTooltip}
        arrow={props.hideArrow ?? true}
        visible={isOpen}
        onCreate={(tippy) => setTippy(tippy)}
        className={getClassName(
          "!rounded-[10px] shadow-[0_0_20px_rgba(0,0,0,0.15)] [&>.tippy-content]:rounded-[10px]",
          props.className,
          props.tooltipBgColor
            ? `${colorMap[props.tooltipBgColor]} ${
                colorBgMap[props.tooltipBgColor]
              }`
            : "[&>.tippy-arrow]:text-white [&>.tippy-content]:bg-white",
        )}
        onClickOutside={() => setIsOpen(false)}
        interactive={true}
        content={props.children}
      >
        <button
          onClick={() => {
            setIsOpen(!isOpen);
          }}
          onMouseEnter={() => {
            props.openOnHover ? setIsOpen(true) : null;
          }}
          onMouseLeave={() => {
            props.openOnHover ? setIsOpen(false) : null;
          }}
        >
          {props.elementTrigger(isOpen)}
        </button>
      </Tippy>
    </MenuContext.Provider>
  );
}

export default Menu;
