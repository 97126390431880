import { ReactNode } from "react";
import { useMenuContext } from ".";

export type MenuItemProps = {
  children: ReactNode;
  keepOpen?: boolean;
  onClick?: () => void;
};

export function MenuItem(props: MenuItemProps) {
  const { toggle } = useMenuContext();

  return (
    <div
      className="py-0 text-body-15 font-semibold text-onyx"
      role="menuitem"
      tabIndex={0}
      onClick={() => {
        props.onClick?.();
        if (props.keepOpen) {
          return;
        }
        toggle();
      }}
    >
      {props.children}
    </div>
  );
}

export default MenuItem;
