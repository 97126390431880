import { ReactNode } from "react";
import { getClassName } from "@pairtreefamily/utils";
import CaretDown from "../icons/caretDown";

type NavBarItemProps = {
  children: ReactNode;
  showCaret?: boolean;
  isActive?: boolean;
};
function NavBarItem(props: NavBarItemProps) {
  const className = getClassName(
    !props.isActive && "border-colorless",
    !!props.isActive && "border-teal text-teal",
    "border-t-4 hover:border-teal",
    "hover:text-teal",
    "min-w-max py-6 h-full",
    "text-onyx lg:text-link-button block",
  );

  return (
    <span className={className}>
      {props.children}
      {props.showCaret && <CaretDown className="inline lg:ml-1 xl:ml-2" />}
    </span>
  );
}

export default NavBarItem;
