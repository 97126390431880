export type CaretRightProps = {
  className?: string;
  unsize?: boolean;
};

export function CaretRight(props: CaretRightProps) {
  return (
    <svg
      width={props.unsize ? undefined : "7"}
      height={props.unsize ? undefined : "10"}
      viewBox="0 0 7 10"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={props.className}
    >
      <path
        d="M4.12361 5L0.310016 8.39279C0.110858 8.57421 0.000302211 8.81183 0.000302232 9.05847C0.000302254 9.30512 0.110858 9.54274 0.310016 9.72416C0.405265 9.81111 0.520991 9.88052 0.64987 9.928C0.778749 9.97548 0.917972 10 1.05873 10C1.19949 10 1.33871 9.97548 1.46759 9.928C1.59647 9.88052 1.71219 9.81111 1.80744 9.72416L6.41221 5.62818C6.60028 5.45729 6.70471 5.23322 6.70471 5.00062C6.70471 4.76803 6.60028 4.54396 6.41221 4.37307L1.80744 0.275844C1.71219 0.188894 1.59647 0.119482 1.46759 0.0719991C1.33871 0.0245175 1.19948 4.81282e-07 1.05873 4.93588e-07C0.917971 5.05893e-07 0.778748 0.0245176 0.649869 0.0719991C0.52099 0.119482 0.405264 0.188894 0.310015 0.275844C0.110645 0.457296 -4.27751e-05 0.695049 -4.27536e-05 0.941839C-4.2732e-05 1.18863 0.110645 1.42638 0.310015 1.60783L4.12361 5Z"
        className="fill-current"
      />
    </svg>
  );
}

export default CaretRight;
