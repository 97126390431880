import { useRouter } from "next/router";
import { getImageURL } from "@/utils/getImageURL";
import Link from "next/link";
import { Colorways } from "src/theme";
import { NavSection } from ".";
import { CtaButton, CtaButtonType } from "../CtaButton";
import { useMenuContext } from "../menu";
import Menu from "../menu";
import MenuItem from "../menu/menuItem";
import NavBarItem from "../navBar/navBarItem";
import Image from "next/image";
import { LinkType } from "./constants";
import { getNavItemUrl } from "./getNavItemUrl";

const HeadingCTAButton = ({
  colorway,
  headingCta,
}: {
  colorway: Colorways;
  headingCta: CtaButtonType;
}) => {
  const { toggle } = useMenuContext();

  const closeMenu = () => {
    toggle();
  };

  return (
    <div className="m-auto">
      <CtaButton
        colorway={colorway}
        classNames="!py-2"
        onClick={closeMenu}
        {...headingCta}
      />
    </div>
  );
};

export function DropDownMenuItem(props: NavSection & { isActive: boolean }) {
  const section = props;

  const colorway = (section.colorway as Colorways) ?? "default";

  const colorwayText: Record<Colorways, String> = {
    [Colorways.Moms]: "text-moms",
    [Colorways.Families]: "text-families",
    [Colorways.Pros]: "text-pros",
    [Colorways.Default]: "text-teal",
    [Colorways.Learning]: "text-learning",
    [Colorways.None]: "text-default",
  };

  return (
    <Menu
      hideArrow={false}
      className="!max-w-none"
      id={`nav-bar__${section._id}`}
      elementTrigger={() => (
        <NavBarItem showCaret isActive={props.isActive}>
          {section.sectionLinkText}
        </NavBarItem>
      )}
    >
      <div className="flex w-[380px] flex-col gap-4 p-8">
        {section.headingCta && (
          <>
            {!!section.icon && !!section.icon.asset && (
              <Image
                src={getImageURL(section.icon).url()}
                width={50}
                height={50}
                alt={section.header ?? ""}
                className="m-auto h-12 w-auto"
              />
            )}
            <div
              className={`${colorwayText[colorway]} text-head-6 text-center`}
            >
              {section.header}
            </div>

            {section.headingCta.shouldDisplay && (
              <HeadingCTAButton
                colorway={colorway}
                headingCta={section.headingCta}
              />
            )}
          </>
        )}

        {!!section.navSectionLinks && section.navSectionLinks.length > 0 && (
          <div className="border-t-4 border-platinum py-4">
            {section.navSectionLinks.map((navItem) => (
              <div key={navItem._key} className={`${colorway}-hover py-2`}>
                <MenuItem keepOpen={false}>
                  {navItem.linkType === LinkType.PhoneNumber && (
                    <Link href={`tel:${getNavItemUrl(navItem)}`}>
                      <>
                        {navItem.text}
                        <div
                          className={`${colorwayText[colorway]} text-body-17`}
                        >
                          {getNavItemUrl(navItem)}
                        </div>
                      </>
                    </Link>
                  )}

                  {navItem.linkType !== LinkType.PhoneNumber && (
                    <Link href={getNavItemUrl(navItem)}>
                      <span className="navLink">{navItem.text}</span>
                    </Link>
                  )}
                </MenuItem>
              </div>
            ))}
          </div>
        )}

        <div className="flex flex-row">
          {section.footerCtaPrimary?.shouldDisplay && (
            <CtaButton
              colorway={colorway}
              classNames="!py-2 !px-4 text-onyx"
              {...section.footerCtaPrimary}
            />
          )}

          {section.footerCtaSecondary?.shouldDisplay &&
            section.footerCtaSecondary.link && (
              <CtaButton
                colorway={colorway}
                classNames="!py-2 !px-4 ml-4 text-onyx border-colorless"
                {...section.footerCtaSecondary}
              />
            )}
        </div>
      </div>
    </Menu>
  );
}
