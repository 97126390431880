export type CloseProps = {
  className?: string;
  unsize?: boolean;
};

export function Close(props: CloseProps) {
  return (
    <svg
      className={props.className}
      width={props.unsize ? undefined : "12"}
      height={props.unsize ? undefined : "11"}
      viewBox="0 0 12 11"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M10.9244 8.50035L7.65939 5.23723L10.9219 1.97223C11.2981 1.59723 11.2981 0.994101 10.9231 0.617851L10.62 0.314101C10.4419 0.131601 10.1981 0.0322266 9.94189 0.0322266C9.94064 0.0322266 9.94064 0.0322266 9.94064 0.0322266C9.68439 0.0322266 9.44376 0.132852 9.26376 0.313477L6.00001 3.57973L2.73501 0.317226C2.36064 -0.0577735 1.75814 -0.0596487 1.38189 0.315351L1.07814 0.615976C0.895013 0.795351 0.795013 1.0391 0.795013 1.29473C0.795013 1.5516 0.895638 1.79348 1.07626 1.97348L4.34251 5.23723L1.07939 8.50223C0.703138 8.87723 0.703138 9.48035 1.07814 9.85785L1.38126 10.161C1.56064 10.3435 1.80376 10.4429 2.06001 10.4429C2.31501 10.4429 2.55876 10.341 2.73814 10.1616L6.00126 6.89598L9.26501 10.1579C9.45001 10.3447 9.69626 10.4391 9.94314 10.4391C10.1875 10.4391 10.4325 10.346 10.6194 10.1597L10.9225 9.85723C11.1063 9.6791 11.2056 9.43535 11.2056 9.1791C11.2069 8.92285 11.1063 8.68098 10.9256 8.50035H10.9244Z"
        fill="currentColor"
      />
    </svg>
  );
}

export default Close;
