export enum NavSectionType {
  SingleLink = "Single Link",
  Dropdown = "Dropdown",
}

export enum LinkType {
  ModularPage = "Modular Page",
  CustomPage = "Custom Page",
  PhoneNumber = "Phone Number",
}
