export const Arrow = ({ className }: { className: string }) => (
  <svg
    className={className}
    width="75"
    height="54"
    viewBox="0 0 75 54"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M15.5997 1.79439C10.7195 7.4681 6.66366 14.0223 2.20368 20.0608C0.16054 22.827 3.82079 17.8074 4.43176 17.1555C6.51002 14.9378 9.04976 13.3303 10.9795 10.9452C11.3442 10.4944 15.5837 3.83043 15.6982 4.90394C16.3944 11.4368 21.6149 16.2269 23.0557 22.5689"
      stroke="currentColor"
      strokeWidth="3"
      strokeLinecap="round"
    />
    <path
      d="M15.8525 9.79039C8.93306 19.0926 6.31482 38.5911 18.8489 46.4917C31.383 54.3924 56.2158 54.599 73.4914 38.2689"
      stroke="currentColor"
      strokeWidth="3"
      strokeLinecap="round"
    />
  </svg>
);
